

// export const FARM_LIST = "/incentives/list";
// export const MY_FARM_LIST = "/incentives/myFarm";
export const UPDATE_MY_FARM = "/incentives/updateMyFarm";
export const DELETED_FARM_LIST = "/incentives/deleted";


export const UPDATE_FARM = "/incentives/update";
export const GET_STAKING_TRANSACTION_LIST = "/trxn/stakingTrxn";

export const NFT_LIST = "/nft/ownerNft";

export const CREATE_FARM = "/farm/create"
export const FARM_LIST = "/farm/list";
export const MY_FARM_LIST = "/farm/myfarm";
export const DEPOSIT_FARM = "/farm/deposit"
export const DELETE_FARM =  "/farm/delete"
export const UNSTAKE_FARM =  "/farm/unstake"
export const MULTI_STAKE =  "/farm/multistake"
export const UNSTAKE_ALL =  "/farm/unstakeall"
export const CLAIM_LIST =  "/farm/claim"












